import store from "@/store"
import ReSignPage from "@/components/ReSignPage.vue";
import ForgetPW from "@/components/ForgetPW.vue"

export default {
  data() {
    return {
      modalShow: true,
      resignpage: false,
      ForgetShow: false,
      signdata: {
        email: "",
        password: ""
      }
    };
  },
  components: {
    ReSignPage,
    ForgetPW
  },
  methods: {
   async Login(method) {
      switch (method) {
        case "Google":
          store.dispatch("user/getGoogle").then((data) => {
            console.log(data)
            store.dispatch("user/Login", {
              method: "Google",
              data: {
                googleuid: data.Ca
              }
            }).then((data) => {
              if (data.data.state) {
                console.log("登入完成，跳轉首頁");
                this.$router.push({
                  name: "index"
                });
              }
            });
          });
          break;
        case "Account":
          const response = await store.dispatch("user/login", {
            method: "Account",
            data: {
              account: this.signdata.email,
              password: this.signdata.password
            }
          })
          console.log(response);
          if (response) {
            this.$router.push({
              name: "index"
            });
          }

          break;
      }

    },
    test(data) {},
    resignopen() {
      this.resignpage = true;
    },
    resignclose() {
      this.resignpage = false;
    },

  },
  computed: {
    langresign: {
      get() {
        return store.state.Public.lang.resign;
      }
    },

    langLogin: {
      get() {
        return store.state.Public.lang.Login;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next();
  }
};
