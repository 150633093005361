<template>
  <div id="login_bg" class="w-100 h-100">
    <nav id="menu" class="fake_menu"></nav>
    <div id="login">
      <aside>

        <figure>
          <router-link :to='{name:"index"}' tab="a" class='text-white'>
            <img src="@/assets/img/dagee-logo.png" width="155" data-retina="true" alt=""
              class="logo_sticky">
          </router-link>
        </figure>

        <!-- <form> -->
        <!-- <div class="access_social">
            <a href="#0" class="social_bt google"
              @click='Login("Google")'>{{langLogin.AuthGoogle}}</a>
          </div> 

          <div class="divider"><span>Or</span></div>
-->
        <div class="form-group">
          <div class="d-flex align-items-center">
            <i class="icon_mail_alt mr-1"></i>
            <label class="text-nowrap text-left mr-1 d-none d-sm-block" 
              style="min-width:60px">{{langresign.email}}</label>
            <input type="email" v-model='signdata.email' class="form-control px-2" name="email"
              id="email">
          </div>
        </div>

        <div class="form-group">
          <div class="d-flex align-items-center">
            <i class="icon_lock_alt mr-1"></i>
            <label class="text-nowrap text-left mr-1 d-none d-sm-block"
              style="min-width:60px">{{langresign.password}}</label>
            <input type="password" v-model='signdata.password' class="form-control px-2" name="password"
              id="password">
          </div>
        </div>

        <div class="clearfix add_bottom_30">
          <div class="float-right mt-1">
            <a id="forgot" href="javascript:void(0);" @click.prevent='ForgetShow=!ForgetShow'>
              {{langLogin.forgetpassword}}
            </a>
          </div>
        </div>

        <a href="#" class="btn_1 rounded full-width" @click='Login("Account")'>
          {{langLogin.LoginSubmit}}
        </a>

        <div class="text-center add_top_10">
          {{langLogin.newsignpre}}
          <strong>
            <a href="#sign-in-dialog" title="Log In"
              v-magnificPopup={open:resignopen,close:resignclose}>{{langLogin.signtext}}！
            </a>
          </strong>
        </div>
        <!-- </form> -->
        <div class="copy">© 2019 Dagee</div>
      </aside>
    </div>

    <div id="sign-in-dialog" class="zoom-anim-dialog dialog mfp-hide">
      <div class="small-dialog-header">
        <h3>註冊</h3>
      </div>
      <ReSignPage :caseOI='test' v-if='resignpage' />
    </div>

    <ForgetPW :show.sync='ForgetShow' v-if='ForgetShow'></ForgetPW>

  </div>
</template>

<script>
  import control from "./js/Login"
  export default control;

</script>
